



























































import { debounce } from "@/helpers/debounce";
import { Messages } from "@/models/enums/messages.enum";
import {
  AssetDataSources,
  ResponseGetDetailInquiry,
} from "@/models/interface/assets.interface";
import { currencyFormat } from "@/validator/globalvalidator";
import Vue, { PropType } from "vue";
export default Vue.extend({
  props: {
    dataAssetHistory: {
      type: Object as PropType<ResponseGetDetailInquiry>,
    },
    currentTab: String,
  },
  data() {
    return {
      totalElements: 1 as number,
      limit: 10 as number,
      form: this.$form.createForm(this, { name: "openSourceLine" }),
      formRules: {
        assetNumber: {
          label: "lbl_asset_number",
          name: "assetNumber",
          placeholder: "lbl_asset_number_placeholder",
          decorator: [
            "assetNumber",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        book: {
          label: "lbl_book",
          name: "book",
          placeholder: "lbl_book_placeholder",
          decorator: [
            "book",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        unit: {
          label: "lbl_qty",
          name: "unit",
          placeholder: "lbl_qty_placeholder",
          decorator: [
            "unit",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      dataSource: [] as AssetDataSources[],
      columnsTable: [
        {
          title: "Cost",
          dataIndex: "originalCost",
          key: "originalCost",
          width: 120,
        },
        {
          title: "Invoice Number",
          dataIndex: "invoiceNumber",
          key: "invoiceNumber",
          width: 120,
        },
        {
          title: "Invoice Line",
          dataIndex: "invoiceLine",
          key: "invoiceLine",
          width: 120,
        },
        {
          title: "Description",
          dataIndex: "description",
          key: "description",
          width: 250,
        },
        {
          title: "Supplier",
          dataIndex: "supplier",
          key: "supplier",
          width: 250,
        },
        {
          title: "PO Number",
          dataIndex: "purchaseOrderNumber",
          key: "purchaseOrderNumber",
          width: 120,
        },
        {
          title: "Source Batch",
          dataIndex: "source",
          key: "source",
          width: 120,
        },
      ],
    };
  },
  watch: {
    dataAssetHistory: {
      immediate: true,
      deep: true,
      handler() {
        const {
          bookMethod,
          bookName,
          bookType,
          unit,
          assetNo,
          assetDataSources,
        } = this.dataAssetHistory;
        debounce(() => {
          this.form.setFieldsValue({
            assetNumber: assetNo,
            book: `${bookName} - ${bookMethod} - ${bookType}`,
            unit: unit,
          });
          this.dataSource = assetDataSources;
        }, 500);
      },
    },
    dataSource() {
      this.dataSource.forEach(
        dataMap => (dataMap.originalCost = currencyFormat(dataMap.originalCost))
      );
    },
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
