var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c(
        "a-row",
        { staticClass: "mt-2" },
        [
          _c(
            "a-col",
            { attrs: { span: "12", sm: "24", md: "12" } },
            [
              _c(
                "a-form",
                _vm._b(
                  { attrs: { layout: "vertical", form: _vm.form } },
                  "a-form",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.$t(_vm.formRules.assetNumber.label) }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.assetNumber.decorator,
                            expression: "formRules.assetNumber.decorator"
                          }
                        ],
                        attrs: {
                          disabled: "",
                          name: _vm.formRules.assetNumber.name,
                          placeholder: _vm.$t(
                            _vm.formRules.assetNumber.placeholder
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.$t(_vm.formRules.book.label) }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.book.decorator,
                            expression: "formRules.book.decorator"
                          }
                        ],
                        attrs: {
                          disabled: "",
                          name: _vm.formRules.book.name,
                          placeholder: _vm.$t(_vm.formRules.book.placeholder)
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.$t(_vm.formRules.unit.label) }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.unit.decorator,
                            expression: "formRules.unit.decorator"
                          }
                        ],
                        attrs: {
                          disabled: "",
                          name: _vm.formRules.unit.name,
                          placeholder: _vm.$t(_vm.formRules.unit.placeholder)
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { staticClass: "mt-2" },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("TableCustom", {
                attrs: {
                  dataSource: _vm.dataSource,
                  columns: _vm.columnsTable,
                  scroll: { x: "calc(700px + 50%)" },
                  paginationcustom: false,
                  defaultPagination: true
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }